import { AxiosResponse } from 'axios';
import axiosClient from '../clients/axios-client';
import { Company, CompanyUpdateDTO } from '../data/models/company';
import { GoogleUser, User, UsersFilter } from '../data/models/user';
import { stringifyReqObj } from '../utils/common-utils';
import { Template } from '../data/models/template';

export async function fetchCompany(): Promise<Company> {
	const graphqlQuery = `
            {
                company{
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.company;
}

export async function updateCompany(
	company: CompanyUpdateDTO
): Promise<Company> {
	const graphqlQuery = `
            mutation {
                updateCompany(
                    data: ${stringifyReqObj(company)},
                ) {
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateCompany;
}

export async function updateQBInfoSheet(googleId: string): Promise<Company> {
	const graphqlQuery = `
            mutation {
                updateQBInfoSheet(
                    googleId: "${googleId}",
                ) {
                    ${companyROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.updateQBInfoSheet;
}

export async function fetchGoogleUsers(): Promise<GoogleUser[]> {
	const graphqlQuery = `
            {
                googleUsers{
                    ${googleUsersROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.googleUsers;
}

export async function fetchTemplates(): Promise<{
	list: Template[];
	count: number;
}> {
	const graphqlQuery = `
            {
                templates {
                    ${templatesROGraphQl}
                }
            }
        `;

	const response: AxiosResponse = await axiosClient({
		url: 'graphql',
		method: `POST`,
		data: {
			query: graphqlQuery,
		},
	});
	return response?.data?.templates;
}

const templatesROGraphQl = `
    list {
        id
        name
        description
        created
        updated
        mimeType
        fileSize
        versions {
            version
            googleId
        }
        activeVersion {
            version
            googleId
        }
        active
    }
    count
`;

const companyROGraphQl = `
    id
    name
    projectConfig {
        dynamicVariables {
            id
            value
            description
        }
    }
    quickLinks {
        id
        title
        url
        icon
        order
    }
    quickBooksTimeConnection {
        lastFetchedRecordsFromDb
        failure
        lastFailureReason
        lastFailureDate
        qbInfoSheets {
            key
            value {
                googleFileId
                qbProjectName
                lastUpdated
                failure
                failureReason
                sheetName
            }
        }
    }
`;

const googleUsersROGraphQl = `
    name {
        givenName
        familyName
    }
    primaryEmail
    creationTime
    id
`;

import React from 'react';
import { ProjectStatuses, ProjectTypes } from '../../data/models/project';
import styled from 'styled-components';
import MuiChip from '@mui/material/Chip';
import { Spinner } from '../common/BusySpinner';

interface ProjectTypeDisplayProps {
	type: string;
}

const ProjectTypeDisplay: React.FC<ProjectTypeDisplayProps> = ({ type }) => {
	if (!type) {
		return null;
	}

	const color = ProjectTypes[type]?.color || '#808080';

	return (
		<TypeChip
			label={ProjectTypes[type]?.display || type}
			chipColor={color}
		/>
	);
};

export default ProjectTypeDisplay;

interface TypeChipProps {
	chipColor: string;
}

const TypeChip = styled(MuiChip)<TypeChipProps>`
	background-color: ${(props) => props.chipColor} !important;
	// & .MuiChip-label {
	// 	color: ${({ theme }) => theme.palette.success.main};
	// }
	height: 24px !important;
`;
